import React from "react"
import PropTypes from 'prop-types'
import Select from 'react-select'

import ClientsItem from "./clientsItem"
import FilterGroup from './filterGroup'
import FilterWrap from './filterWrap'
import { getMarkets } from '../helper'
import { filterStyles } from './filterStyles'
import * as style from './clients.module.scss'

class Clients extends React.Component {
  constructor(props) {
    super()
    this.state = {
      markets: null,
      showCount: 8  // # of Clients to display
    }



    let clientsProps = props.clients



    let clientsSorted = {

    }

    const clientOrder = [
      "Decathlon",
      "HIPP",
      "Sierpc"
    ]


    for(let i=0; i<clientOrder.length; i++) {

        let searchTitle = clientOrder[i]

        for(var j=0; i<clientsProps.length; j++) {
          if(clientsProps[j].title == searchTitle) {
            // __FOUND is set to the index of the element

            let foundClient = clientsProps[j]

            clientsProps.splice(j, 1)

            clientsProps.unshift(foundClient)

            break;
          }
      }


    }





    this.clientList = clientsProps

    console.log("clientsProps", clientsProps)

    this.markets = getMarkets(clientsProps)
    this.handleLoadMore = this.handleLoadMore.bind(this)
    this.handleFilterBlur = this.handleFilterBlur.bind(this)
  }

  handleFilterBlur(event, label) {
    this.setState({[label]: {...event}})
  }

  handleLoadMore() {
    this.setState({
      showCount: this.state.showCount + 8
    })
  }

  render() {
    let clients = this.clientList


    if (this.state.markets && this.state.markets.value) {
      clients = clients.filter(client => client.ClientData.market && client.ClientData.market.name === this.state.markets.value)
    }

    return (
      <>
        <FilterGroup>
          <FilterWrap>
            <Select
              styles={filterStyles}
              placeholder='Wybierz rynek...'
              onChange={ (event) => { this.handleFilterBlur(event, 'markets') } }
              options={this.markets}
              value={this.state.markets}
              isSearchable={false}
            />
          </FilterWrap>
        </FilterGroup>

        <section id={style.clients}>
          <div className={style.wrap}>
            {clients
              .slice(0, this.state.showCount)
              .map(client => <ClientsItem key={client.title} title={client.title} {...client.ClientData} />)
            }
          </div>

          {clients.length > this.state.showCount && (
            <div className="mh-button-wrap">
              <button className="mh-button" onClick={this.handleLoadMore}>Zobacz więcej</button>
            </div>
          )}
        </section>
      </>
    )
  }
}

Clients.propTypes = {
  clients: PropTypes.array.isRequired
}

export default Clients
