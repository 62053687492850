import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Address from "../components/address"
import ContactInfo from "../components/contactInfo"
import Clients from '../components/clients'
import Description from "../components/description"
import Leadership from "../components/leadership"

import Large2 from '../images/blobs/large2.inline.svg'
import "../styles/page-officeDetail.scss"

const OfficeDetail = ({ data }) => {
  const page = data.wpOffice
  const jobLink = page.OfficeData.jobLink.url || page.OfficeData.jobLink.email
  let address = page.OfficeAddress.address[0].address1.trim + ", "
  if (page.OfficeAddress.address[0].address2) {
    address += page.OfficeAddress.address[0].address2.trim() + ", "
  }
  if (page.OfficeAddress.address[0].address3) {
    address += page.OfficeAddress.address[0].address3
  }

  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "@id": data.site.siteMetadata.siteUrl + page.link,
    "name": page.title + " Biuro",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": address
      // "streetAddress": "1600 Saratoga Ave",
      // "addressLocality": "San Jose",
      // "addressRegion": "CA",
      // "postalCode": "95129",
      // "addressCountry": "US"
    },
    "image": [
      data.site.siteMetadata.siteUrl + page.Header.headerImage.localFile.childImageSharp.gatsbyImageData.src
     ],
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": page.OfficeAddress.address[0].location.latitude,
      "longitude": page.OfficeAddress.address[0].location.longitude
    },
    "telephone": page.OfficeAddress.address[0].phoneNumber
  }

  return (
    <Layout id="office-detail" darkMode={page.Header.darkMode}>
      <Seo
        title={page.title}
        seoTitle={page.SeoMeta.seoTitle}
        description={page.SeoMeta.seoDescription}
        schema={schema}
      />

      <GatsbyImage
        image={page.Header.headerImage.localFile.childImageSharp.gatsbyImageData}
        alt={page.Header.headerImage.altText}
        title={page.Header.headerImage.title}
        style={{ height: '60vw', maxHeight: '500px'}} />

      <section className="detail-header">
        <div className="detail-header-main">
          <a href="/contact" className="go-back">&larr; Powrót do biur</a>
          <h1>{page.title}</h1>
          <Description {...page.Description} />
          <ContactInfo info={page.ContactInfo} />
        </div>
        <div className="detail-header-aux">
          <Address addresses={page.OfficeAddress.address} />
        </div>
      </section>

      { page.OfficeData.clients && 
        page.OfficeData.market && 
        page.OfficeData.market.name && 
        page.OfficeData.market.name !== 'North America' && (
        <div></div>
      )}

      <div className="page-dark">
        <div className="large2-wrap">
          <Large2 className="large2"/>
          { page.OfficeData.people && (
            <section className="our-leadership">
              <h2>Nasze przywództwo</h2>
              <Leadership people={page.OfficeData.people} />
            </section>
          )}
          <div className="container">
            <section className="container-narrow">
              <h2>Zobacz, co robimy</h2>
              <a href="/work" className="mh-button invert">Zobacz prace</a>
            </section>

            { jobLink && (
              <section className="container-narrow">
                <h2>Pokaż nam, na co Cię stać</h2>
                { page.OfficeData.jobLink.url && (
                  <a href={page.OfficeData.jobLink.url} className="mh-button invert" target="_blank" rel="noreferrer">Oferty pracy</a>
                )}
                { page.OfficeData.jobLink.email && (
                  <a href={'mailto: ' + page.OfficeData.jobLink.email} className="mh-button invert">Oferty pracy</a>
                )}
              </section>
            )}
          </div>
        </div>
      </div>

    </Layout>
  );
}

export default OfficeDetail

export const query = graphql`query ($slug: String) {
  site {
    siteMetadata {
      siteUrl
    }
  }
  wpOffice(slug: {eq: $slug}) {
    title
    link
    ContactInfo {
      mediaInquiries
      newBusiness
    }
    Description {
      description
    }
    Header {
      darkMode
      headerImage {
        altText
        title
        localFile {
          childImageSharp {
            gatsbyImageData(height: 640, layout: FULL_WIDTH)
          }
        }
      }
    }
    OfficeAddress {
      address {
        address1
        address2
        address3
        location {
          latitude
          longitude
        }
        phoneNumber
        title
      }
    }
    OfficeData {
      market {
        name
      }
      jobLink {
        url
        email
      }
      people {
        ... on WpPerson {
          title
          PersonData {
            bio
            jobTitle
          }
        }
      }
      clients {
        ... on WpClient {
          title
          ClientData {
            project {
              ... on WpProject {
                link
              }
            }
            market {
              name
              databaseId
            }
            logo {
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 320, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
    SeoMeta {
      seoTitle
      seoDescription
    }
  }
}
`
